import React from 'react';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CurrentLocation = ({ address }) => {
    const { t } = useTranslation();

    return (
        <Box display="flex" alignItems="center" mb={0}>
            <MyLocationIcon color="primary" sx={{ mr: 1 }}/>
            <Typography variant="subtitle1" color="primary">
                {t('Current Location')}: <strong>{address}</strong>
            </Typography>
        </Box>
    );
};

export default CurrentLocation;
