import React, {useEffect, useMemo, useState} from 'react';
import {createTheme} from '@mui/material/styles';
import SunCalc from 'suncalc';

export const useDarkMode = (currentLocation) => {
    // State to store the user's manual choice
    const [userSetDarkMode, setUserSetDarkMode] = useState(null);

    // Use state initialization function to check localStorage
    const [darkMode, setDarkMode] = useState(() => {
        // Check if localStorage has a user preference
        const savedMode = localStorage.getItem('darkMode');
        if (savedMode) {
            return savedMode === 'true';
        } else {
            // If no preference is saved, use sunset time
            const now = new Date();
            const {sunset, sunrise} = SunCalc.getTimes(now, currentLocation.lat, currentLocation.lng);
            return now >= sunset || now < sunrise;
        }
    });


    // Effect to store user preference
    useEffect(() => {
        // Only store in localStorage if userSetDarkMode is true or false (not null)
        if (userSetDarkMode !== null) {
            localStorage.setItem('darkMode', darkMode);
        }
    }, [darkMode, userSetDarkMode]); // Depend on userSetDarkMode to determine if the setting was user-initiated

    const theme = useMemo(() =>
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light',
                },
            }),
        [darkMode]
    );

    const toggleDarkMode = () => {
        setUserSetDarkMode(!darkMode);
        setDarkMode(!darkMode);
    };

    return {theme, toggleDarkMode, darkMode};
};