import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        supportedLngs: [
            'en',
            'es',
            'ar',
            'el',
            'tr',
            'ru',
            'uk'
        ],
        backend: {
            loadPath: process.env.PUBLIC_URL + '/languages/{{lng}}.json',
        },
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
        }
    });
export default i18n;