// useCurrentLocation.js
import { useState, useEffect } from 'react';

const useCurrentLocation = (lat, lng) => {
    const [currentAddress, setCurrentAddress] = useState('Loading current location...');

    useEffect(() => {
        const cacheKey = `${lat},${lng}`;
        const cachedAddress = localStorage.getItem(cacheKey);

        if (cachedAddress) {
            setCurrentAddress(cachedAddress);
        } else {
            const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;

            fetch(nominatimUrl)
                .then(response => response.json())
                .then(data => {
                    if (data.address) {
                        const readableAddress = formatAddress(data.address);
                        localStorage.setItem(cacheKey, readableAddress);
                        setCurrentAddress(readableAddress);
                    } else {
                        setCurrentAddress('Address not found');
                    }
                })
                .catch(error => {
                    console.error('Error fetching address: ', error);
                    setCurrentAddress('Unable to retrieve address');
                });
        }
    }, [lat, lng]);

    const formatAddress = (address) => {
        return `${address.road || ''}, ${address.city || address.town || ''}, ${address.country || ''}`;
    };

    return currentAddress;
};

export default useCurrentLocation;