import React from 'react';
import {Button, Paper, Typography} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import MapOutlined from '@mui/icons-material/GetApp';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const BlurredImage = styled.img`
    width: 100%;
    height: 400px;
    filter: blur(8px);
    object-fit: cover;
    object-position: center;
    
    @media (min-width:600px) {
        height: auto;
    }
`;

const StyledMapIcon = styled(MapIcon)`
  && {
    font-size: 60px; /* default size */
    color: white;

    @media (max-width: 600px) {
      font-size: 40px;
    }
  }
`;

const StyledTypography = styled(Typography)`
     && {
      color: white;
      text-align: center;
      margin-top: 10px;
      
      @media (max-width: 600px) {
        font-size: 0.875rem;
      }
    }
`;

const StyledButton = styled(Button)`
  && {
  margin-top: 10px;

      @media (max-width: 600px) {
        font-size: 0.75rem; /* smaller font size for smaller screens */
      }
  }
`;

const MapPlaceholder = ({onClick}) => {
    const {t} = useTranslation();
    // Define image paths here for clarity
    const imagePath = process.env.PUBLIC_URL + '/images/';
    const placeholderImage = `${imagePath}placeholder.jpg`; // Fallback image

    // Define the srcset string with local fallback images
    const srcsetString = `
        ${imagePath}placeholder-150x150.jpg 150w,
        ${imagePath}placeholder-300x107.jpg 300w,
        ${imagePath}placeholder-360x398.jpg 360w,
        ${imagePath}placeholder-640x398.jpg 640w,
        ${imagePath}placeholder-768x273.jpg 768w,
        ${imagePath}placeholder-1024x364.jpg 1024w
    `;

    // Choose your sizes string based on your design's breakpoints
    const sizesString = `
        (max-width: 150px) 150px,
        (max-width: 300px) 300px,
        (max-width: 360px) 360px,
        (max-width: 640px) 640px,
        (max-width: 768px) 768px,
        100vw
    `;

    // Use the default placeholder image as the src
    const src = placeholderImage;

    return (
        <div style={{position: 'relative', width: '100%', cursor: 'pointer'}} onClick={onClick}>
            <BlurredImage
                srcSet={srcsetString}
                sizes={sizesString}
                src={src}
                alt={t('mapPlaceholder.altText')}
                width="1221"
                height="400"
            />
            <Paper
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // For better visibility
                }}
                elevation={3}
            >
                <StyledMapIcon />
                <StyledTypography variant="h6">
                    {t('mapPlaceholder.clickToLoad')}
                </StyledTypography>
                <StyledButton variant="contained" color="primary" onClick={onClick} startIcon={<MapOutlined />}>
                    {t('mapPlaceholder.loadButton')}
                </StyledButton>
            </Paper>
        </div>
    );
};

export default MapPlaceholder;
