import React from 'react';
import { Switch, FormGroup, FormControlLabel, useTheme } from '@mui/material';
import Brightness7Icon from '@mui/icons-material/Brightness7'; // sun icon for light mode
import Brightness4Icon from '@mui/icons-material/Brightness4'; // moon icon for dark mode

const DarkModeSwitch = ({ darkMode, toggleDarkMode }) => {
    const theme = useTheme();
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        checked={darkMode}
                        onChange={toggleDarkMode}
                        icon={<Brightness7Icon />}
                        checkedIcon={<Brightness4Icon />}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                            },
                            '& .MuiSwitch-track': {
                                backgroundColor: theme.palette.mode === 'dark' ? '#aab4be' : '#8796A5',
                            },
                        }}
                    />
                }
                labelPlacement="start"
                sx={{
                    margin: 0,
                    '& .MuiFormControlLabel-label': {
                        color: theme.palette.text.primary,
                    },
                }}
            />
        </FormGroup>
    );
};


export default DarkModeSwitch;
