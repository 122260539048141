import React from 'react';
import PharmacyInfo from './PharmacyInfo';
import MapComponent from './MapComponent';
import {useTranslation} from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UpdateIcon from '@mui/icons-material/Update';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import {Box, Divider, Grid, Paper, Typography, useMediaQuery, useTheme} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';


const NearestPharmacySection = ({
                                    selectedPharmacy,
                                    currentPoint,
                                    pharmacies,
                                    handleLocationChange,
                                    setSelectedPharmacy,
                                    lastUpdate
                                }) => {
    const {t, i18n} = useTranslation();
    const formattedLastUpdate = new Date(lastUpdate).toLocaleDateString(i18n.language, {
        weekday: 'long', // full name of the day of the week
        year: 'numeric', // numeric year
        month: 'long', // full name of the month
        day: 'numeric', // numeric day
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Paper elevation={2} sx={{p: 2}}>
            <Grid container justifyContent={{xs: 'center', sm: 'space-between'}} gap={1} alignItems="center">
                <Grid item xs={'auto'} sm={'auto'}>
                    <Box display="flex" alignItems="center">
                        <LocalPharmacyIcon sx={{mr: 1, color: 'secondary.main'}}/>
                        <Typography variant="h6" component="h2" mr={1}>
                            {t('Nearest On-Duty Pharmacy')}
                        </Typography>
                        <Tooltip
                            title={t('Based on your current location, this is the closest on-duty pharmacy available to you.')}>
                            <HelpOutlineIcon color="action"/>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={'auto'} sm={'auto'}>
                    <Box display="flex" alignItems="center">
                        <UpdateIcon sx={{fontSize: '1rem', mr: 0.5}}/>
                        <Typography variant="caption" color="text.secondary">
                            {t('Last updated')}: {formattedLastUpdate}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{my: 2}}/>
            <PharmacyInfo pharmacy={selectedPharmacy} currentPoint={currentPoint}/>
            <MapComponent currentPoint={currentPoint} pharmacies={pharmacies}
                          onLocationChange={handleLocationChange}
                          setSelectedPharmacy={setSelectedPharmacy}/>
        </Paper>
    )
};

export default NearestPharmacySection;
