// src/utils.js

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
}

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

export function findClosestPharmacy(lat, lon, pharmacies) {
    let closest = null;
    let closestDist = Infinity;

    pharmacies.forEach((pharmacy) => {
        const dist = getDistanceFromLatLonInKm(lat, lon, pharmacy.latitude, pharmacy.longitude);
        if (dist < closestDist) {
            closestDist = dist;
            closest = {
                ...pharmacy,
                distance: dist
            };
        }
    });

    return closest;
}
