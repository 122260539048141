import React, {useEffect, useRef, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from 'react-leaflet';
import L from 'leaflet';
import {useTranslation} from 'react-i18next';
import MapPlaceholder from './MapPlaceholder';

const MapComponent = ({currentPoint, onLocationChange, pharmacies, setSelectedPharmacy}) => {
    const {t} = useTranslation();
    const [showMap, setShowMap] = useState(false);
    const mapRef = useRef();
    // Constants for caching
    const CACHE_KEY = 'showMapStatus';
    const CACHE_DURATION = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

    // Function to handle map loading and cache the status
    const handleLoadMap = () => {
        setShowMap(true);
        const cacheData = {
            showMap: true,
            timestamp: Date.now()
        };
        localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
    };

    useEffect(() => {
        // Check the cache on component mount
        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
            const {showMap: cachedShowMap, timestamp} = JSON.parse(cachedData);
            const isCacheValid = Date.now() - timestamp < CACHE_DURATION;
            if (isCacheValid && cachedShowMap) {
                setShowMap(true);
            }
        }
    }, []);

    // Function to create a custom icon
    const createIcon = (iconUrl, iconSize) => new L.Icon({
        iconUrl: iconUrl,
        iconSize: iconSize,
        iconAnchor: [iconSize[0] / 2, iconSize[1]],
        popupAnchor: [0, -iconSize[1] / 2]
    });

    const currentLocationIconUrl = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    const pharmacyIconUrl = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';

    const currentLocationIcon = createIcon(currentLocationIconUrl, [30, 30]);
    const pharmacyIcon = createIcon(pharmacyIconUrl, [25, 25]);

    // Event handler component for the map
    const LocationEventHandler = () => {
        const map = useMapEvents({
            click(e) {
                if (!map.dragging._moved) {
                    onLocationChange(e.latlng);
                }
            },
            locationfound(e) {
                onLocationChange(e.latlng);
                map.flyTo(e.latlng, map.getZoom());
            },
        });

        return null; // Component does not render anything
    };


    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            map.flyTo(currentPoint, map.getZoom());
        }
    }, [currentPoint]);


    if (!showMap) {
        // Render the placeholder with an interaction to load the map
        return <MapPlaceholder onClick={handleLoadMap}/>;
    }

    return (
        <MapContainer center={currentPoint} zoom={13} style={{height: '400px'}} ref={mapRef}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <LocationEventHandler/>
            <Marker position={currentPoint} icon={currentLocationIcon}>
                <Popup>{t('Current Location')}</Popup>
            </Marker>
            {pharmacies.map(pharmacy => (
                <Marker
                    key={pharmacy.id}
                    position={[pharmacy.latitude, pharmacy.longitude]}
                    icon={pharmacyIcon}
                >
                    <Popup>{pharmacy.pharmacy_name}</Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default MapComponent;
