// usePharmacies.js
import { useEffect, useState } from 'react';
import { findClosestPharmacy } from '../utils';

const usePharmacies = (currentPoint) => {
    const [pharmacies, setPharmacies] = useState([]);
    const [lastUpdate, setLastUpdate] = useState([]);
    const [selectedPharmacy, setSelectedPharmacy] = useState(null);
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/wp-json/pharmacy-map-finder/v1/pharmacies`;

    useEffect(() => {
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setLastUpdate(data.lastUpdate);
                setPharmacies(data.pharmacies);
            })
            .catch(error => console.error('Error fetching pharmacies:', error));
    }, []);

    // When currentPoint or pharmacies change, find the closest pharmacy
    useEffect(() => {
        if (pharmacies.length > 0) {
            const closestPharmacy = findClosestPharmacy(currentPoint.lat, currentPoint.lng, pharmacies);
            setSelectedPharmacy(closestPharmacy);
        }
    }, [currentPoint, pharmacies]);

    return { pharmacies, setPharmacies, selectedPharmacy, setSelectedPharmacy, lastUpdate };
};

export default usePharmacies;