import './App.css';
import 'leaflet/dist/leaflet.css';
import React, {useEffect, useState} from 'react';
import usePharmacies from './hooks/usePharmacies';
import useGeocoding from './hooks/useGeocoding';
import {useTranslation} from 'react-i18next';
import {Box, Container, CssBaseline} from '@mui/material';
import useClosestPharmacy from './hooks/useClosestPharmacy';
import useCurrentLocation from './hooks/useCurrentLocation';
import Header from './Components/Header';
import LocationSection from './Components/LocationSection';
import NearestPharmacySection from './Components/NearestPharmacySection';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useDarkMode} from './hooks/useDarkMode';
import Snackbar from '@mui/material/Snackbar';

const App = () => {
    const {
        currentPoint,
        setCurrentPoint,
        handleAddressSearch,
        snackbarOpen,
        handleCloseSnackbar,
        locationPermissionDenied,
    } = useGeocoding();
    const {pharmacies, setPharmacies, setSelectedPharmacy, lastUpdate} = usePharmacies(currentPoint);
    const currentAddress = useCurrentLocation(currentPoint.lat, currentPoint.lng);
    const selectedPharmacy = useClosestPharmacy(currentPoint, pharmacies);

    const {t, i18n} = useTranslation();
    const {darkMode, toggleDarkMode} = useDarkMode(currentPoint);

    // Merge dark mode and RTL into a single theme
    const [theme, setTheme] = useState(createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light', // Set dark or light mode
        },
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
    }));

    useEffect(() => {
        // Create a new theme instance with updated direction and dark mode settings
        const newTheme = createTheme({
            palette: {
                mode: darkMode ? 'dark' : 'light', // Set dark or light mode
            },
            direction: i18n.language === 'ar' ? 'rtl' : 'ltr', // Set text direction
        });

        setTheme(newTheme); // Update the theme state
        document.body.dir = newTheme.direction; // Update the direction in the body tag

    }, [i18n.language, darkMode, setTheme]); // Add setTheme to dependency array

    const handleLocationChange = (latlng) => {
        setCurrentPoint(latlng);
    };

    const [showSearch, setShowSearch] = useState(false); // New state to control the visibility of the search bar

    // Function to toggle the search bar visibility
    const toggleSearchBar = (shouldShow) => {
        if (shouldShow !== undefined) {
            setShowSearch(shouldShow);
        } else {
            setShowSearch(!showSearch);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Container maxWidth="lg">
                <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode}/>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={snackbarOpen}
                    autoHideDuration={10000}
                    onClose={handleCloseSnackbar}
                    message={t('location_fallback_message')}
                    ContentProps={{
                        style: {
                            backgroundColor: 'rgb(211, 47, 47)', // Use a color that suits your design
                            color: 'white', // Ensure text is readable
                        },
                    }}
                />
                <Box mt={4}>
                    <LocationSection
                        showSearch={showSearch}
                        toggleSearchBar={toggleSearchBar}
                        currentAddress={currentAddress}
                        handleAddressSearch={(address) => {
                            handleAddressSearch(address);
                            toggleSearchBar(false); // Hide the search bar after searching
                        }}
                        locationPermissionDenied={locationPermissionDenied}
                    />
                </Box>
                <Box mt={4} mb={3}>
                    <NearestPharmacySection
                        selectedPharmacy={selectedPharmacy}
                        currentPoint={currentPoint}
                        pharmacies={pharmacies}
                        lastUpdate={lastUpdate}
                        handleLocationChange={handleLocationChange}
                        setSelectedPharmacy={setSelectedPharmacy}
                    />
                </Box>
            </Container>
        </ThemeProvider>
    );
};
export default App;
