import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormControl, MenuItem, Select} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { Helmet } from 'react-helmet';

const supportedLanguages = [
    {code: 'tr', label: 'Türkçe'},
    {code: 'en', label: 'English'},
    {code: 'el', label: 'Ελληνικά'},
    {code: 'ar', label: 'العربية'},
    {code: 'ru', label: 'Русский'},
    {code: 'uk', label: 'Українська'},
    {code: 'es', label: 'Español'}
];

const LanguageSelector = () => {
    const {i18n, t} = useTranslation();
    const getLanguageFromUrl = () => new URLSearchParams(window.location.search).get('lang');
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nextLng') || navigator.language.split('-')[0]);

    useEffect(() => {
        // Effect to initialize language from URL or localStorage on component mount
        const urlLang = getLanguageFromUrl();
        const initialLanguage = urlLang && supportedLanguages.some(lang => lang.code === urlLang)
            ? urlLang
            : localStorage.getItem('i18nextLng') || navigator.language.split('-')[0] || 'en';

        if (initialLanguage !== selectedLanguage) {
            setSelectedLanguage(initialLanguage);
        }
    }, []); // Run only once on mount

    useEffect(() => {
        // Effect to update application state when selectedLanguage changes
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
        document.documentElement.lang = selectedLanguage;
        updateUrlWithLang(selectedLanguage);
    }, [selectedLanguage, i18n]); // Run when selectedLanguage changes


    const updateUrlWithLang = (lang) => {
        const url = new URL(window.location);
        url.searchParams.set('lang', lang);
        window.history.pushState({}, '', url);
    };

    const changeLanguage = (event) => {
        setSelectedLanguage(event.target.value);
    };

    return (
        <>
            <Helmet>
                <title>{t('seo.title')}</title>
                <meta name="description" content={t('seo.description')} />
                {supportedLanguages.map((language) => (
                    <link
                        key={language.code}
                        rel="alternate"
                        hreflang={language.code}
                        href={`${window.location.origin}${window.location.pathname}?lang=${language.code}`} />
                ))}
                <link rel="alternate" hreflang="x-default" href={`${window.location.origin}${window.location.pathname}`} />
                <link
                    rel="preload"
                    as="image"
                    href={`${process.env.PUBLIC_URL}/images/placeholder-360x398.jpg`}
                    media="(max-width: 359px)"
                />
                <link
                    rel="preload"
                    as="image"
                    href={`${process.env.PUBLIC_URL}/images/placeholder-640x398.jpg`}
                    media="(min-width: 360px) and (max-width: 639px)"
                />
                <link
                    rel="preload"
                    as="image"
                    href={`${process.env.PUBLIC_URL}/images/placeholder-1024x364.jpg`}
                    media="(min-width: 640px)"
                />
            </Helmet>
            <FormControl sx={{minWidth: 120}}>
                <Select
                    labelId="language-selector-label"
                    id="language-selector"
                    value={selectedLanguage}
                    onChange={changeLanguage}
                    IconComponent={LanguageIcon}
                >
                    {supportedLanguages.map((language) => (
                        <MenuItem key={language.code} value={language.code}>
                            {language.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default LanguageSelector;