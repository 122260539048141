import React, { useRef } from 'react';
import { Box, Button, Collapse, Grid, Paper, Typography } from '@mui/material';
import CurrentLocation from './CurrentLocation';
import SearchBar from './SearchBar';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useTranslation } from 'react-i18next';

const LocationSection = ({
                             showSearch,
                             toggleSearchBar,
                             currentAddress,
                             handleAddressSearch,
                             locationPermissionDenied
                         }) => {
    const { t } = useTranslation();
    const textFieldRef = useRef(null);

    const handleToggleSearchBar = () => {
        toggleSearchBar();

        // Set focus on the TextField when the button is clicked
        if (!showSearch && textFieldRef.current) {
            setTimeout(() => textFieldRef.current.focus(), 300); // Set focus after the transition
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 2, mb: 4, borderRadius: 2 }}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{
                marginBottom: showSearch ? 2 : 0,
                transition: 'margin-bottom 300ms ease-in-out',
            }}>
                <Grid item xs={12} md={8}>
                    <Typography variant="subtitle1" color="textSecondary">
                        <CurrentLocation sx={{ mr: 1, verticalAlign: 'bottom' }} address={currentAddress} />
                    </Typography>
                </Grid>
                <Grid
                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                    item xs={12} md={4} sx={{ display: 'flex' }}
                >
                    <Button variant="outlined" color="primary" onClick={handleToggleSearchBar}>
                        {showSearch ? t('Hide search') : t('Search location')}
                    </Button>
                </Grid>
            </Grid>
            <Collapse in={showSearch}>
                <Box mt={2}>
                    <SearchBar onSearch={handleAddressSearch} inputRef={textFieldRef} />
                </Box>
            </Collapse>
        </Paper>
    );
}

export default LocationSection;
