// SearchBar.js
import React, {useRef} from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import {InputAdornment} from '@mui/material';
import {useTranslation} from 'react-i18next';

const SearchBar = ({onSearch}) => {
    const {t} = useTranslation();
    const textFieldRef = useRef(null);

    return (
        <div>
            <TextField
                ref={textFieldRef}
                fullWidth
                placeholder={t('Search location')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                }}
                autoComplete={'off'}
                variant="outlined"
                onKeyPress={(e) => e.key === 'Enter' && onSearch(e.target.value)}
            />
        </div>
    );
};

export default SearchBar;
