import React from 'react';
import { Box, Grid } from '@mui/material';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import DarkModeSwitch from "./DarkModeSwitch";
const Header = ({ darkMode, toggleDarkMode }) => {
    const { t } = useTranslation();

    return (
        <Box mt={4}>
            <Grid container
                  spacing={2}
                  justifyContent={{ xs: 'center', sm: 'space-between' }}
                  alignItems="center">
                <Grid item xs={12} sm="auto" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={process.env.PUBLIC_URL + "/logo.png"} alt={t('logo_alt_text')} width="390" height="132" style={{ height: '50px', width: 'auto' }}/>
                </Grid>
                <Grid item xs={6} sm="auto" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LanguageSelector />
                </Grid>
                <Grid item xs={6} sm="auto" sx={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                    <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Header;
