// PharmacyInfo.js
import React, {useState} from 'react';
import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Collapse,
    IconButton,
    Link,
    Stack,
    Typography
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import GoogleMapIcon from '@mui/icons-material/Place'; // Placeholder for Google Maps icon
import AppleIcon from '@mui/icons-material/Apple'; // Placeholder for Apple icon
import YandexIcon from '@mui/icons-material/HelpOutline'; // Placeholder for Yandex icon
import BingIcon from '@mui/icons-material/Map'; // Placeholder for Bing Maps icon
import OsmIcon from '@mui/icons-material/Map'; // Placeholder for OpenStreetMap icon
import HereIcon from '@mui/icons-material/Map'; // Placeholder for Here WeGo icon
import WazeIcon from '@mui/icons-material/Traffic';

const PharmacyInfo = ({pharmacy, currentPoint}) => {
    const {t} = useTranslation();
    const [showMoreMaps, setShowMoreMaps] = useState(false);

    if (!pharmacy) {
        return (
            <Card>
                <CardContent>
                    <Typography variant="subtitle1">{t('no_pharmacy_info')}</Typography>
                </CardContent>
            </Card>
        );
    }

    const toggleMoreMaps = () => {
        setShowMoreMaps(!showMoreMaps);
    };

    const userLocation = {lat: currentPoint.lat, lng: currentPoint.lng};

    const googleMapsDirectionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userLocation.lat},${userLocation.lng}&destination=${pharmacy.latitude},${pharmacy.longitude}&travelmode=driving`;
    const appleMapsDirectionsUrl = `http://maps.apple.com/?saddr=${userLocation.lat},${userLocation.lng}&daddr=${pharmacy.latitude},${pharmacy.longitude}&dirflg=d`;
    const yandexMapsDirectionsUrl = `https://yandex.com/maps/?rtext=${userLocation.lat},${userLocation.lng}~${pharmacy.latitude},${pharmacy.longitude}&rtt=auto`;
    const bingMapsDirectionsUrl = `https://www.bing.com/maps?rtp=adr.${userLocation.lat},${userLocation.lng}~adr.${pharmacy.latitude},${pharmacy.longitude}`;
    const openStreetMapDirectionsUrl = `https://www.openstreetmap.org/directions?engine=fossgis_osrm_car&route=${userLocation.lat}%2C${userLocation.lng}%3B${pharmacy.latitude}%2C${pharmacy.longitude}`;
    const hereWeGoDirectionsUrl = `https://wego.here.com/directions/default/Konum:${userLocation.lat},${userLocation.lng}/Eczane:${pharmacy.latitude},${pharmacy.longitude}`;
    const wazeDirectionsUrl = `https://www.waze.com/ul?ll=${pharmacy.latitude}%2C${pharmacy.longitude}&from=${userLocation.lat}%2C${userLocation.lng}&navigate=yes`;

    const formattedPhoneNumber = pharmacy.phone_number ? pharmacy.phone_number.replace(/[^0-9+]/g, '') : '';

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div">
                    {pharmacy.pharmacy_name}
                </Typography>
                <Box display="flex" alignItems="center" mt={2}>
                    <LocationOnIcon color="primary"/>
                    <Typography variant="subtitle1" ml={1}>
                        {pharmacy.address}
                    </Typography>
                </Box>
                {pharmacy.phone_number && (
                    <Box display="flex" alignItems="center" mt={1}>
                        <PhoneIcon color="primary"/>
                        <Typography variant="subtitle1" ml={1}>
                            <Link href={`tel:${formattedPhoneNumber}`} color="inherit">
                                {pharmacy.phone_number}
                            </Link>
                        </Typography>
                    </Box>
                )}
                {pharmacy.distance && (
                    <Box display="flex" alignItems="center" mt={1}>
                        <Chip
                            icon={<DirectionsWalkIcon/>}
                            label={t('distance_label', {distance: pharmacy.distance.toFixed(2)})}
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                )}
            </CardContent>
            <CardActions sx={{
                '& > :not(:first-child)': {
                    marginLeft: 0,
                }, flexWrap: 'wrap', gap: '12px'
            }}>
                <Button
                    size="small"
                    href={googleMapsDirectionsUrl}
                    startIcon={<GoogleMapIcon/>}
                    sx={{color: '#db4437'}}
                    target="_blank"
                    aria-label={t('actions.open_in', {service: 'Google Maps'})}
                >
                    {t('actions.open_in', {service: 'Google Maps'})}
                </Button>
                <Button
                    size="small"
                    href={appleMapsDirectionsUrl}
                    startIcon={<AppleIcon/>}
                    sx={{color: '#a3aaae'}}
                    target="_blank"
                    aria-label={t('actions.open_in', {service: 'Apple Maps'})}
                >
                    {t('actions.open_in', {service: 'Apple Maps'})}
                </Button>
                <Button
                    size="small"
                    href={yandexMapsDirectionsUrl}
                    startIcon={<YandexIcon/>}
                    sx={{color: theme => theme.palette.warning.main}} // Use a theme color for better contrast
                    target="_blank"
                    aria-label={t('actions.open_in', {service: 'Yandex Maps'})}
                >
                    {t('actions.open_in', {service: 'Yandex Maps'})}
                </Button>
                <IconButton
                    onClick={toggleMoreMaps}
                    aria-expanded={showMoreMaps}
                    aria-label={showMoreMaps ? t('Show fewer map options') : t('Show more map options')}
                    sx={{
                        transform: showMoreMaps ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: '0.3s',
                        display: showMoreMaps ? 'none' : 'inline-flex' // Hides the button when more maps are shown
                    }}
                >
                    <ExpandMoreIcon/>
                </IconButton>
                <Collapse in={showMoreMaps} timeout="auto" unmountOnExit>
                    <Stack direction="row" sx={{justifyContent: 'flex-start', flexWrap: 'wrap', gap: '12px'}}>
                        <Button
                            size="small"
                            href={bingMapsDirectionsUrl}
                            startIcon={<BingIcon/>} // Actual Bing Maps icon
                            sx={{color: '#F05022'}}
                            target="_blank"
                            aria-label={t('actions.open_in', {service: 'Bing Maps'})}
                        >
                            {t('actions.open_in', {service: 'Bing Maps'})}
                        </Button>
                        <Button
                            size="small"
                            href={openStreetMapDirectionsUrl}
                            startIcon={<OsmIcon/>} // Actual OpenStreetMap icon
                            sx={{color: '#7EBC6F'}}
                            target="_blank"
                            aria-label={t('actions.open_in', {service: 'OpenStreetMap'})}
                        >
                            {t('actions.open_in', {service: 'OpenStreetMap'})}
                        </Button>
                        <Button
                            size="small"
                            href={hereWeGoDirectionsUrl}
                            startIcon={<HereIcon/>} // Actual Here WeGo icon
                            sx={{color: '#0066A1'}}
                            target="_blank"
                            aria-label={t('actions.open_in', {service: 'Here WeGo'})}
                        >
                            {t('actions.open_in', {service: 'Here WeGo'})}
                        </Button>
                        <Button
                            size="small"
                            href={wazeDirectionsUrl}
                            startIcon={<WazeIcon/>} // Actual Waze icon
                            sx={{color: '#33CCFF'}}
                            target="_blank"
                            aria-label={t('actions.open_in', {service: 'Waze'})}
                        >
                            {t('actions.open_in', {service: 'Waze'})}
                        </Button>
                    </Stack>
                </Collapse>
            </CardActions>
        </Card>
    );
};

export default PharmacyInfo;
