import {useEffect, useState} from 'react';

const defaultLocation = {lat: 35.20712552546065, lng: 33.31963856738551};
const ipInfoToken = 'a45f99da3c5498';

const useGeocoding = () => {
    const [currentPoint, setCurrentPoint] = useState(defaultLocation);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // state to control snackbar visibility
    const [locationPermissionDenied, setLocationPermissionDenied] = useState(false);

    const fetchLocationFromIP = async () => {
        try {
            const response = await fetch(`https://ipinfo.io/json?token=${ipInfoToken}`);
            const data = await response.json();
            const [latitude, longitude] = data.loc.split(',');
            const newPoint = {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
            };
            setCurrentPoint(newPoint);
        } catch (error) {
            console.error('Error fetching location from IP:', error);
            setCurrentPoint(defaultLocation); // Fallback to default location
        }
    };

    const checkAndUpdateLocation = (newLat, newLng) => {
        // No valid last geolocation, set new location
        const newPoint = {lat: newLat, lng: newLng};
        setCurrentPoint(newPoint);
    };

    const updateLocationInterval = 90000; // Update every 90 seconds
    let intervalId;

    const updateCoordinates = () => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const {latitude, longitude} = position.coords;
                checkAndUpdateLocation(latitude, longitude);
            },
            (error) => {
                console.error('Geolocation permission denied or error occurred.', error);
                clearInterval(intervalId); // Clear the interval to stop further attempts
                fetchLocationFromIP(); // Fallback to IP-based location
                setSnackbarOpen(true);
                setLocationPermissionDenied(true); // Reflect the permission denied status
            },
            {
                enableHighAccuracy: true,
                maximumAge: 30000,
                timeout: 27000
            }
        );
    }

    const startLocationUpdates = () => {
        if (navigator.geolocation) {
            updateCoordinates();
            intervalId = setInterval(updateCoordinates, updateLocationInterval);
        } else {
            console.error('Geolocation is not supported by this browser.');
            fetchLocationFromIP(); // Fallback to IP-based location
        }
    };

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            setCurrentPoint(defaultLocation);
        } else {
            startLocationUpdates();
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    const handleAddressSearch = (address) => {
        // Encode the address for use in a URL query
        const encodedAddress = encodeURIComponent(address.trim());
        // Construct the geocoding API URL
        const geocodingUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodedAddress}`;

        // Perform the geocoding request
        fetch(geocodingUrl)
            .then(response => response.json())
            .then(data => {
                // Check if any results were returned
                if (data && data.length > 0) {
                    // Use the first result for this example
                    const result = data[0];

                    // Update the currentPoint with the new coordinates
                    setCurrentPoint({
                        lat: parseFloat(result.lat),
                        lng: parseFloat(result.lon)
                    });
                } else {
                    // Handle the case where no results are found, e.g., show an alert or message
                    alert('No results found for that address.');
                }
            })
            .catch(error => {
                // Handle any errors that occurred during the fetch
                console.error('Geocoding error:', error);
                alert('Error searching for address. Please try again.');
            });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return {
        currentPoint,
        setCurrentPoint,
        handleAddressSearch,
        snackbarOpen,
        handleCloseSnackbar,
        locationPermissionDenied,
    };
};

export default useGeocoding;
