// useClosestPharmacy.js
import { useEffect, useState } from 'react';
import { findClosestPharmacy } from '../utils';

const useClosestPharmacy = (currentPoint, pharmacies) => {
    const [selectedPharmacy, setSelectedPharmacy] = useState(null);

    useEffect(() => {
        if (pharmacies.length > 0) {
            const closestPharmacy = findClosestPharmacy(currentPoint.lat, currentPoint.lng, pharmacies);
            setSelectedPharmacy(closestPharmacy);
        }
    }, [currentPoint, pharmacies]);

    return selectedPharmacy;
};

export default useClosestPharmacy;